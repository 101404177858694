import React from 'react'

const LogoLab = props => (
    <svg
        height={`${props.height}pt`}
        viewBox="-88 0 512 512"
        width={`${props.width}pt`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m221.789062 82.484375c0 22.199219-18 40.199219-40.199218 40.199219-22.199219 0-40.199219-18-40.199219-40.199219s18-40.199219 40.199219-40.199219c22.199218 0 40.199218 18 40.199218 40.199219zm0 0"
            fill="#b18cd9"
        />
        <path
            d="m283.566406 23.363281c0 12.902344-10.457031 23.363281-23.363281 23.363281-12.902344 0-23.363281-10.460937-23.363281-23.363281 0-12.902343 10.460937-23.363281 23.363281-23.363281 12.90625 0 23.363281 10.460938 23.363281 23.363281zm0 0"
            fill="#996acc"
        />
        <path
            d="m228.683594 223.410156v-86.507812h-122.113282v86.507812c0 40.542969-12.011718 80.175782-34.519531 113.894532l-63.328125 94.875c-22.765625 34.105468 1.683594 79.820312 42.6875 79.820312h232.433594c41.007812 0 65.453125-45.714844 42.6875-79.820312l-63.328125-94.875c-22.507813-33.71875-34.519531-73.351563-34.519531-113.894532zm0 0"
            fill="#91d4f2"
        />
        <path
            d="m228.683594 185.59375v-48.691406h-122.113282v83.304687c14.984376-6.285156 37.464844-14.027343 61.058594-16.050781 21.960938-1.882812 44.617188-10.699219 61.054688-18.5625zm0 0"
            fill="#59c2e8"
        />
        <path
            d="m227.441406 156.957031h-119.628906c-18.929688 0-34.273438-15.34375-34.273438-34.273437 0-18.929688 15.34375-34.277344 34.273438-34.277344h119.628906c18.929688 0 34.277344 15.347656 34.277344 34.277344-.003906 18.929687-15.347656 34.273437-34.277344 34.273437zm0 0"
            fill="#c2eafb"
        />
        <path
            d="m129.484375 122.683594c0-18.929688 15.34375-34.277344 34.273437-34.277344h-55.945312c-18.929688 0-34.273438 15.347656-34.273438 34.277344 0 18.925781 15.34375 34.273437 34.273438 34.273437h55.945312c-18.929687 0-34.273437-15.34375-34.273437-34.273437zm0 0"
            fill="#91d4f2"
        />
        <path
            d="m261.398438 334.558594-196.8125 13.933594-20.238282 30.320312-35.625 53.367188c-22.765625 34.105468 1.683594 79.820312 42.6875 79.820312h232.433594c41.007812 0 65.453125-45.714844 42.6875-79.820312zm0 0"
            fill="#996acc"
        />
        <path
            d="m64.585938 348.492188c17.464843 33.730468 59.972656 53.339843 196.8125-13.9375 0 0-43.199219 4.292968-98.703126-12.734376-68.0625-20.875-98.109374 26.671876-98.109374 26.671876zm0 0"
            fill="#b18cd9"
        />
        <g fill="#454545">
            <path d="m231.300781 436.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726562v-12.828126c0-4.265624 3.457031-7.726562 7.726562-7.726562 4.265625 0 7.722657 3.460938 7.722657 7.726562v12.828126c0 4.265624-3.457032 7.726562-7.722657 7.726562zm0 0" />
            <path d="m103.957031 436.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726562v-12.828126c0-4.265624 3.457031-7.726562 7.726562-7.726562 4.265625 0 7.726563 3.460938 7.726563 7.726562v12.828126c0 4.265624-3.460938 7.726562-7.726563 7.726562zm0 0" />
            <path d="m183.117188 431.457031c0-10.898437-6.933594-19.734375-15.488282-19.734375-8.554687 0-15.488281 8.835938-15.488281 19.734375 0 10.898438 6.933594 19.730469 15.488281 19.730469 8.554688 0 15.488282-8.832031 15.488282-19.730469zm0 0" />
        </g>
    </svg>
)

export default LogoLab
